.trailsText {
    position: relative;
    width: 100%;
    height: 120px;
    line-height: 80px;
    font-size: 6em;
    font-weight: 800;
    letter-spacing: -0.05em;
    will-change: transform, opacity;
    overflow: hidden;
    color: #2cb3d5;
}

/* Media query for smaller screens (adjust breakpoint as needed) */
@media (max-width: 768px) {
    .trailsText {
        font-size: 4em; /* Font size for mobile */
        height: 130px; /* Adjust height if needed for mobile */
        line-height: 70px;
    }
}

.trailsText > div {
    padding-right: 0.05em;
    overflow: hidden;
}

.container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    background-color: #000000;
    min-height: 100vh;
}
